import React from 'react'
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  makeStyles,
  Box,
  Typography
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import { useStyles } from '../../../utils/styled'
import {
  TimestampToFormatShotTime,
  ConvertTimeStamp
} from '../../../utils/helper'

const customeStyles = makeStyles((theme) => ({
  list: {
    alignItems: 'center',
    padding: 0,
    gap: '6px',
    '& .MuiListItemText-root': {
      margin: 0
    }
  },
  listRoot: {
    margin: 0,
    padding: 0
  },
  listIcon: {
    minWidth: 0
  }
}))
export default function Timer({ startAt, endAt, icon = 'clock' }) {
  const customClass = customeStyles()
  const classes = useStyles()

  const startDate = moment(ConvertTimeStamp(startAt).seconds * 1000)
  const endDate = moment(ConvertTimeStamp(endAt).seconds * 1000)
  const checkSameDate = startDate.isSame(endDate, 'date')

  return (
    <List classes={{ root: customClass.listRoot }}>
      <ListItem className={customClass.list}>
        <ListItemIcon classes={{ root: customClass.listIcon }}>
          <FontAwesomeIcon icon={['far', icon]} size="lg" />
        </ListItemIcon>
        <ListItemText
          classes={{ root: customClass.listRoot }}
          primary={
            <>
              <Box display="flex" alignItems="center">
                <Typography variant="h6">
                  {TimestampToFormatShotTime(startAt)}
                </Typography>
                <FontAwesomeIcon
                  icon={['far', 'arrows-h']}
                  className={`${classes.textGray} ${classes.mx1}`}
                />
              </Box>
            </>
          }
        />
      </ListItem>
      <ListItem className={customClass.list}>
        <ListItemText
          classes={{ root: customClass.listRoot }}
          style={{ marginLeft: '23px' }}
          primary={
            <Typography variant="h6">
              {checkSameDate
                ? moment(endDate).format('HH:mm')
                : TimestampToFormatShotTime(endAt)}
            </Typography>
          }
        />
      </ListItem>
    </List>
  )
}
