import { useState, useEffect, useContext } from 'react'
import randomstring from 'randomstring'
import moment from 'moment'
import { realtimedb as db } from '../Services/firebaseApp'
import {
  ref,
  orderByChild,
  query as realtimeQuery,
  onChildChanged,
  onChildAdded,
  orderByKey,
  equalTo,
  onValue,
  limitToLast
} from 'firebase/database'

import AppContext from '../Services/AppContext'
import SessionChatService from '../Services/SessionChatService'
import { MessageType, EventType } from '../utils/type'
import { generateRandomNumber } from '../utils/helper'

export default function useChatMessages(
  authenticated: any,
  sessionId: string,
  event: EventType
) {
  const [chatMessages, setChatMessages] = useState<MessageType[]>([])
  const [pinMessage, setPinMessage] = useState<MessageType | null>(null)
  const [hasNextMessage, setHasNextMessage] = useState(false)
  const [isLoadingMoreMessages, setIsLoadingMoreMessages] = useState(false)
  const appContext = useContext(AppContext.Context)
  const { flags } = appContext

  const updateChatMessages = (updateMessage: MessageType) => {
    setChatMessages((prevMessages) =>
      prevMessages.map((message) =>
        message.id === updateMessage.id ? updateMessage : message
      )
    )
  }

  const handleUnPinMessage = async () => {
    if (pinMessage) {
      const newMessage = {
        ...pinMessage,
        pin: false
      }
      await SessionChatService.updateMessages(
        event.id,
        sessionId,
        pinMessage.id,
        newMessage
      )
    }
    setPinMessage(null)
  }

  useEffect(() => {
    let interval: NodeJS.Timer
    if (flags && flags.includes('test-chat-stream')) {
      interval = setInterval(() => {
        const message: MessageType = {
          profileId: randomstring.generate(7).toUpperCase(),
          id: randomstring.generate(7).toUpperCase(),
          displayName: randomstring.generate(6),
          message: randomstring.generate(generateRandomNumber(50, 200)),
          userId: randomstring.generate(6).toUpperCase(),
          photoURL: '',
          ticketRole: 'viewer',
          timestamp: moment().valueOf(),
          pin: false
        }
        setChatMessages((prevMessages) => [...prevMessages, message])
      }, 1000)
    }

    return () => clearInterval(interval)
  }, [flags])

  const getLoadMoreMessages = () => {
    setIsLoadingMoreMessages(true)
    SessionChatService.lazyLoadMessage(
      event.id,
      sessionId,
      chatMessages[0]
    ).then((result) => {
      const { messages, hasNextMessage } = result

      setChatMessages((oldMessage) => [...messages, ...oldMessage])
      setHasNextMessage(hasNextMessage)
      setIsLoadingMoreMessages(false)
    })
  }
  useEffect(() => {
    if (authenticated) {
      const messageListRef = ref(db, `sessionMessages/${event.id}/${sessionId}`)
      const messageListQuery = realtimeQuery(
        messageListRef,
        orderByChild('timestamp'),
        limitToLast(101)
      )

      const unsubscribeInitialFetch = onValue(
        messageListQuery,
        (snapshot) => {
          if (snapshot.exists()) {
            const listOfMessages = Object.keys(snapshot.val()).map(
              (messageId) => {
                return {
                  ...snapshot.val()[messageId],
                  id: messageId
                }
              }
            )
            setChatMessages(
              listOfMessages.length > 100
                ? listOfMessages.slice(1, 101)
                : listOfMessages
            )
            setHasNextMessage(listOfMessages.length > 100 ? true : false)
          }
        },
        {
          onlyOnce: true
        }
      )

      const pinQuery = realtimeQuery(
        messageListRef,
        orderByChild('pin'),
        equalTo(true)
      )
      const unsubscribeInitialPinMesage = onValue(
        pinQuery,
        (snapshot) => {
          if (snapshot.exists()) {
            snapshot.forEach((childSnapshot) => {
              const findMessage = {
                ...childSnapshot.val(),
                id: childSnapshot.key
              }
              setPinMessage(findMessage)
            })
          }
        },
        {
          onlyOnce: true
        }
      )

      // const startKey = push(messageListRef).key

      const messagesQuery = realtimeQuery(
        messageListRef,
        orderByKey(),
        limitToLast(10)
      )
      const messagePinQuery = realtimeQuery(messageListRef, orderByChild('pin'))

      const unsubscribe = onChildAdded(messagesQuery, (snapshot) => {
        const message = { ...snapshot.val(), id: snapshot.key }
        setChatMessages((prevMessages) => [...prevMessages, message])
      })

      const unsubscribeMessageUpdate = onChildChanged(
        messageListRef,
        (snapshot) => {
          const message = { ...snapshot.val(), id: snapshot.key }
          updateChatMessages(message)
        }
      )

      const unsubscribeMessagePin = onChildChanged(
        messagePinQuery,
        (snapshot) => {
          const message = { ...snapshot.val(), id: snapshot.key }
          if (pinMessage) {
            if (!message?.pin) {
              setPinMessage(null)
            }
          }
          if (message.pin) {
            setPinMessage(message)
          }
        }
      )

      return () => {
        unsubscribeInitialFetch()
        unsubscribeInitialPinMesage()
        unsubscribe()
        unsubscribeMessageUpdate()
        unsubscribeMessagePin()
      }
    }
  }, [authenticated, sessionId, event.id])

  return {
    chatMessages,
    hasNextMessage,
    isLoadingMoreMessages,
    getLoadMoreMessages,
    pinMessage,
    handleUnPinMessage
  }
}
