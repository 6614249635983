import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useEffect, useState, useContext } from 'react'
import Countdown, { zeroPad } from 'react-countdown'
import {
  Box,
  Typography,
  makeStyles,
  Hidden,
  Tooltip,
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
  useMediaQuery,
  ClickAwayListener
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import Skeleton from '@material-ui/lab/Skeleton'

import EventContext from '../../../Services/EventContext'
import ChipSessionStatus from '../../../Components/ChipSessionStatus'
import { useStyles, CustomButton } from '../../../utils/styled'
import {
  TimestampToFormatShotTime,
  CheckStateSession,
  ConvertTimeStamp
} from '../../../utils/helper'
import ViewersCount from '../../../Components/Livestream/Chips/ViewersCount'

const customeStyles = makeStyles((theme) => ({
  listRoot: {
    ...theme.lobby.listRoot,
    width: '100%',
    borderRadius: 8,
    padding: '16px',
    marginBottom: theme.spacing(2)
  },
  listRootEnded: {
    width: '100%',
    borderRadius: 8,
    padding: '16px',
    marginBottom: theme.spacing(1)
  },
  list: {
    alignItems: 'center',
    padding: 0,
    gap: '6px',
    '& .MuiListItemText-root': {
      margin: 0
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'start'
    }
  },
  iconRoot: {
    marginRight: 32,
    [theme.breakpoints.down('xs')]: {
      marginRight: 16
    }
  },
  itemIcon: {
    ...theme.lobby.itemIcon,
    minWidth: 24,
    display: 'flex',
    justifyContent: 'center'
  },
  sessionIcon: {
    ...theme.lobby.sessionIcon,
    padding: '30px',
    borderRadius: '8px'
  },
  buttonRoot: {
    [theme.breakpoints.up('xl')]: {
      width: '20%'
    },

    [theme.breakpoints.only('sm')]: {
      width: 'auto'
    }
  }
}))

const List = ({ icon, primary }) => {
  const customClasses = customeStyles()

  return (
    <ListItem component="div" className={customClasses.list}>
      <ListItemIcon className={customClasses.itemIcon}>
        <FontAwesomeIcon icon={['far', icon]} size="lg" />
      </ListItemIcon>
      <ListItemText primary={primary} />
    </ListItem>
  )
}

const SessionList = ({ eventSlug, sessionId, session, onClick }) => {
  const classes = useStyles()
  const customClasses = customeStyles()
  const theme = useTheme()
  const eventContext = useContext(EventContext.Context)
  const [liveStreamState, setLiveStreamState] = useState('wait')
  const [duration, setDuration] = useState()
  const xsSizeDown = useMediaQuery(theme.breakpoints.down('xs'))
  const [isLoaded, setIsLoaded] = useState(true)
  const { t } = useTranslation('event')

  useEffect(() => {
    const start = ConvertTimeStamp(session.startAt).seconds * 1000
    const end = ConvertTimeStamp(session.endAt).seconds * 1000

    const state = CheckStateSession(
      session.isLive,
      start,
      end,
      eventContext.timeCurrent
    )
    setLiveStreamState(state)

    let then = moment(start)
    let now = moment(eventContext.timeCurrent)
    let time = moment.duration(then - now)
    setDuration(time.asMilliseconds())

    if (isLoaded) setIsLoaded(false)
  }, [session.startAt, session.endAt, isLoaded])

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state
      return (
        <ViewersCount
          sessionId={sessionId}
          liveStreamState={liveStreamState}
          eventSlug={eventSlug}
          viewersIncremental={session.viewersIncremental ?? 0}
        />
      )
    } else {
      // Render a countdown
      let now = moment(eventContext.timeCurrent)
      let start = moment(ConvertTimeStamp(session.startAt).seconds * 1000)
      let time = start.endOf('day').from(now)

      return (
        <List
          icon="clock"
          primary={
            <React.Fragment>
              <Typography variant="h6">
                {`${t('starting_in', { adj: days <= 0 ? 'in' : 'on' })}
                  ${
                    days <= 0
                      ? `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(
                          seconds
                        )}`
                      : `${time}`
                  } `}
              </Typography>
            </React.Fragment>
          }
        />
      )
    }
  }

  const Timer = ({ startAt, endAt }) => {
    const startDate = moment(ConvertTimeStamp(startAt).seconds * 1000).format(
      'DD MMM'
    )
    const endDate = moment(ConvertTimeStamp(endAt).seconds * 1000).format(
      'DD MMM'
    )
    let checkSameDate = false
    if (startDate === endDate) {
      checkSameDate = true
    }

    return (
      <List
        icon="calendar-alt"
        primary={
          <React.Fragment>
            <Box
              display="flex"
              flexDirection={!xsSizeDown || checkSameDate ? 'row' : 'column'}
            >
              <Box display="flex" alignItems="center">
                <Typography variant="h6">
                  {TimestampToFormatShotTime(startAt)}
                </Typography>

                <FontAwesomeIcon
                  icon={['far', 'arrows-h']}
                  className={`${customClasses.itemIcon} ${classes.mx1}`}
                />
              </Box>
              <Typography variant="h6" className={classes.mr1}>
                {checkSameDate
                  ? moment(endAt._seconds * 1000).format('HH:mm')
                  : TimestampToFormatShotTime(endAt)}
              </Typography>
            </Box>
          </React.Fragment>
        }
      />
    )
  }

  const ButtonTemplate = ({ onClick }) => {
    let variant = 'contained'
    let btnTitleDesktop = t('join_session')

    if (liveStreamState === 'wait' || liveStreamState === 'ended') {
      variant = 'outlined'
      btnTitleDesktop = t('join_wating_room')
    }

    return (
      <CustomButton
        fullWidth={xsSizeDown ? false : true}
        variant={variant}
        color="primary"
        type="submit"
        onClick={() => onClick()}
      >
        {btnTitleDesktop}
      </CustomButton>
    )
  }

  const [openTooltip, setOpenTooltip] = useState(false)

  const handleTooltipClose = () => setOpenTooltip(false)
  const handleTooltipOpen = () => setOpenTooltip(true)

  const ListSkeleton = () => {
    return (
      <Box
        className={customClasses.listRoot}
        display="flex"
        alignItems="center"
      >
        <Box className={customClasses.iconRoot}>
          <Skeleton variant="circle" width={48} height={48} />
        </Box>

        <Box width="100%">
          <Skeleton width="30%">
            <Typography variant="h4">.</Typography>
          </Skeleton>
          <Skeleton width="60%" className={classes.mt1}>
            <Typography variant="h6">.</Typography>
          </Skeleton>
          <Skeleton width="20%" className={classes.mt1}>
            <Typography variant="h6">.</Typography>
          </Skeleton>
        </Box>
      </Box>
    )
  }

  return (
    <React.Fragment>
      {!isLoaded ? (
        <Box
          className={
            liveStreamState !== 'ended'
              ? customClasses.listRoot
              : customClasses.listRootEnded
          }
          display="flex"
          alignItems={xsSizeDown ? 'start' : 'center'}
          gridGap="16px"
        >
          <Box className={customClasses.sessionIcon}>
            <FontAwesomeIcon icon={['far', session.icon]} size="2x" />
          </Box>

          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection="column"
              gridGap="6px"
              width={xsSizeDown ? '100%' : '70%'}
            >
              <Box display="flex" gridGap="6px">
                <ChipSessionStatus
                  liveStreamState={liveStreamState}
                  isLive={session.isLive}
                />
                <ViewersCount
                  sessionId={sessionId}
                  eventSlug={eventSlug}
                  liveStreamState={liveStreamState}
                  viewersIncremental={session.viewersIncremental ?? 0}
                />
              </Box>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                  open={openTooltip}
                  onClose={handleTooltipClose}
                  title={session.fullTitle}
                  placement="top-start"
                  arrow
                >
                  <Typography
                    className={classes.textEllipsis}
                    onClick={handleTooltipOpen}
                    fontSize="16px"
                  >
                    <strong>{session.title}</strong>
                  </Typography>
                </Tooltip>
              </ClickAwayListener>

              <Timer startAt={session.startAt} endAt={session.endAt} />

              {liveStreamState === 'wait' && (
                <Countdown
                  onComplete={() => setLiveStreamState('start')}
                  date={eventContext.timeCurrent + duration}
                  renderer={renderer}
                />
              )}

              <Hidden smUp>
                <Box mt="10px" className={customClasses.buttonRoot}>
                  <ButtonTemplate onClick={onClick} />
                </Box>
              </Hidden>
            </Box>
            <Hidden xsDown>
              <Box className={customClasses.buttonRoot}>
                <ButtonTemplate onClick={onClick} />
              </Box>
            </Hidden>
          </Box>
        </Box>
      ) : (
        <ListSkeleton />
      )}
    </React.Fragment>
  )
}

export default SessionList
