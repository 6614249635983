import React, { useEffect } from 'react'
import { useTranslation, lo } from 'react-i18next'
import moment from 'moment'
import { Router } from '@reach/router'
import TagManager from 'react-gtm-module'
import HomePage from './Pages/HomePage'
import AuthPage from './Pages/AuthPage'
import EventPage from './Pages/EventPage'
import SignInPage from './Pages/SignInPage'
import SignUpPage from './Pages/SignUpPage'
import AuthCallback from './Pages/User/AuthCallback'
import RedeemTicketPage from './Pages/RedeemTicketPage'
import RedirectCallback from './Pages/RedirectCallback'
import PageNotFound from './Pages/404'
import './assets/eventpop-font.css'
import './assets/stylesheets/index.scss'
import './utils/fontAwesome'

function App() {
  const { i18n } = useTranslation()
  useEffect(() => {
    moment.locale(i18n.language)
  }, [i18n.language])

  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.REACT_APP_TAG_MANAGER })
  }, [])

  return (
    <Router>
      <PageNotFound default />
      <HomePage path="/" />
      <AuthPage path="/auth" />
      <SignInPage path="/signin" />
      <SignUpPage path="/signup" />
      <RedeemTicketPage path="/e/:slug/redeem" />
      <EventPage path="/e/:slug/*" />
      <AuthCallback path="/user/sign_in" />
      <AuthCallback path="/user/sign_out" />
      <RedirectCallback path="/redirect" />
    </Router>
  )
}

export default App
